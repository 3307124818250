import "primereact/resources/themes/saga-orange/theme.css"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"
import 'primeflex/primeflex.css';
import * as React from "react"
import {PageProps} from "gatsby";
import {Link} from "../components/Link";
import styled from 'styled-components';
import {Layout} from "../containers/Layout";


const ITEMS = [
    {
        id: 1
    },
    {
        id: 2
    },
    {
        dir: 'dir',
        items: [
            {
                id: 3
            },
            {
                id: 4
            }
        ]
    }
];


export default function Index(props: PageProps) {
    return <StyledMinimalPage title={'Index'} {...props}>
        <div id={"index"}/>
        <div>
            <Blacklist>Blacklist</Blacklist>
            <h3 className={'subPages'}>SubPages</h3>
            <StyledMenu>
                {mapItems(ITEMS, '/')}
            </StyledMenu>
        </div>
    </StyledMinimalPage>
}

const Blacklist = styled.div`
  font-size: 4rem;
  margin-left: -0.4rem;
`;

function mapItems(items: typeof ITEMS, path: string) {
    return items.map(item => {
        if (item.items) {
            return <li key={item.dir}>
                <div className={'dir'}>{item.dir}/</div>
                <ul>
                    {mapItems(item.items, `${path}${item.dir}/`)}
                </ul>
            </li>
        } else {
            return <li key={item.id}><Link className="link" to={`${path}blacklist_${item.id}`} title={`blacklist_${item.id}`}/></li>
        }
    });
}

const StyledMinimalPage = styled(Layout)`
  .main {
    justify-content: center;  
  }  
  
  .subPages {
    margin-top: 2rem;
  }
`;


const StyledMenu = styled.ul`
  //font-size: 1.6rem;
  list-style: none;
  margin-left: -2rem;
  
  li {
    padding-bottom: 0.2rem;
  }
  
  .link {
      color: #333333;
      &:active, &:hover {
        color: #595959;      
      }
  }
  
  .dir {
    margin-top: 0.4rem;
  }
`;
